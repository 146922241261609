.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 40px;
    font-weight: 800;
  }
  .button{
    background: none;
    border: 1px solid #9146F7;
  }
}
