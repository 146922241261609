.modalHeader {
  margin-left: 60px;
}
.modalHeader .title {
  font-size: 1.7rem;
  color: #336a9e;
}

.modalSubHeader {
  margin-top: 45px;
  margin-left: 60px;
}
.modalSubHeader .title {
  font-size: 1.4rem;
  color: #336a9e;
}

.modalSubHeader hr {
  width: 92.5%;
}

.modalErrorLabel {
  color: #dc5047;

  margin-top: 10px;
}
.modalRow {
  padding-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
}
.modalInputContainer {
  width: 48%;
  display: inline-block;
  vertical-align: top;
}

.lastInput {
  margin-left: 4%;
}
.sendButton {
  margin-left: 60px;
  margin-right: 60px;
  width: calc(100% - 120px);
}

.ContainerMessageCantSlides {
  display: flex;
  justify-content: center;
}

.messageCantSlide {
  color: #e31832;
}
@media (max-width: 780px) {
  .sendButton {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .modalHeader {
    margin-left: 30px;
  }
  .modalSubHeader {
    margin-left: 30px;
  }
  .modalRow {
    margin-left: 0;
  }
  .modalRow {
    margin-left: 30px;
    margin-right: 30px;
  }
  .modalInputContainer {
    display: block;
    width: 100%;
  }
  .lastInput {
    margin-left: 0;
  }
}
.modalInputLabel {
  color: #336a9e;
  letter-spacing: 1px;
}
.modalInputSubLabel {
  color: #7c86a2;
}
